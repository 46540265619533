.form-custom-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: #737373;
}

::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: #737373;
}

.form-control-custom {
  padding: 5px;
  border: 1px solid #000000;
  border-radius: 5px;
}

.form-control-custom:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}
