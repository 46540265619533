.bannerTop-container {
    background-size: cover;
    background-position: center;
    min-height: 312px;
}

.bannerBottom-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 312px;
}
