.thumbnail {
    display: block;

    .free {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('../../../images/theme/free.svg') no-repeat top left transparent;
        z-index: 2;
        top: -4px;
        left: -4px;
    }

    .play {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('../../../images/theme/play.svg') no-repeat center transparent;
        z-index: 1;
        top: 0;
        left: 0;
        transition: transform 0.3s ease-out 0s;
    }
}

.thumbnail:hover .play {
    transform: scale(0.95);
}

.thumbnail_small {
    display: block;
    position: relative;
  
    .free {
      position: absolute;
      width: 100%;
      height: 100%;
      background: url('../../../images/theme/free.svg') no-repeat top left transparent;
      z-index: 2;
      top: -4px;
      left: -4px;
    }
  
    .play {
      position: absolute;
      width: 100%;
      height: 100%;
      background: url('../../../images/theme/play.svg') no-repeat center transparent;
      background-size: 40%;
      z-index: 1;
      top: 0;
      left: 0;
      transition: transform 0.3s ease-out 0s;
    }
  }
  
  .thumbnail_small:hover .play {
    transform: scale(0.95);
  }
  