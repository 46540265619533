.header-btn-fs {
  font-size: var(--header-btn-fs);
}

.profile-img {
  width: 35px;
  height: 35px;
}

.profile-header {
  margin-left: 60px;
}
