.cards-bg {
  background-color: #ececec;
  padding-bottom: 7px;
}

.card-text {
  font-size: 14px;
}

.card-title {
  font-weight: 500;
  font-size: 16px;
}

.nav-link-card .card-title {
  display: block;
  line-height: 1.1 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.rating {
  font-size: 14px;
}

.checked {
  color: orange;
}

.rating-text {
  color: #afafaf;
}

.time-count {
  color: #afafaf;
  font-size: small;
}

.nav-link-card {
  text-decoration: none;
  color: #000;
}

.nav-link-card:hover {
  text-decoration: underline;
}

.guide-cards:hover {
  background-image: linear-gradient(to right, #f7f6f65b, #00aaff18);
}

.listing-results__result {
  transition: background-color 650ms;
  padding: 4px 2px;
}

.listing-results__result:hover {
  background-color: #d4f0ff;
}

/*Fix Height*/

.swiper-slide,
.swiper-slide a,
.swiper-slide .guide-cards {
  min-height: 180px;
}