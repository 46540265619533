:root {
  --bs-blue: #00a9ff;
  --bs-yellow: #FFA800;
  --bs-primary-rgb: 0, 169, 255;
  --bs-dark-rgb: 43, 43, 43;
  --bs-font-sans-serif: "Inter", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --fs-8: 8px;
  --fs-10: 10px;
  --fs-12: 12px;
  --fs-13: 13px;
  --fs-14: 14px;
  --fs-16: 16px;
  --fs-18: 18px;
  --fs-19: 19px;
  --fs-20: 20px;
  --fs-24: 24px;
  --fs-28: 28px;
  --swiper-navigation-size: 20px;
}

/*General*/
main #content {
  margin-top: var(--fs-24);
  border-top: solid 1px rgb(186, 186, 186);
  padding-top: var(--fs-24);
}

hr {
  opacity: 1;
  color: #c8c8c8;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

.border-white {
  border: 1px solid #c8c8c8;
}

/* Temporary */
.badge-complete {
  background-color: #13e85b !important;
  border-radius: 5px;
  font-size: 11px;
  font-family: "Inter", sans-serif;
  padding: 3px 5px;
  color: black;
}