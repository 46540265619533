.btn-primary {
  --bs-btn-bg: var(--bs-blue);
  background-color: var(--bs-blue);
  border-color: var(--bs-blue);
}

.btn-outline-primary {
  color: var(--bs-blue);
  border-color: var(--bs-blue);
}

.favourite-icon {
  cursor: pointer;
  display: none;
}

.btn-alternative {
  --bs-btn-bg: var(--bs-yellow);
  background-color: var(--bs-yellow);
  border-color: var(--bs-yellow);
}

.btn-alternative:hover {
  --bs-btn-bg: #ffb830;
  background-color: #ffb830;
  border-color: #ffb830;
}

.btn-link-simple {
  color: black;
  text-decoration: none;
}

.btn-link-simple:hover {
  color: black;
  text-decoration: underline;
}