.player {
    aspect-ratio: 900/500;
    background: #2b2b2b;

  .play {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../../images/theme/player_play.svg") no-repeat center transparent;
    z-index: 1;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-out 0s;
  }

  .play4 {
    position: absolute;
    width: 50%;
    height: 50%;
    background: url("../../../images/theme/player_play.svg") no-repeat center transparent;
    z-index: 1;
    top: 25%;
    left: 25%;
    transition: transform 0.3s ease-out 0s;
  }
}

.play2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../../../images/theme/player_play.svg") no-repeat center transparent;
  z-index: 1;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-out 0s;
  cursor: pointer;
}

.play3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../../../images/video/play_button.svg") no-repeat center transparent;
  z-index: 1;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-out 0s;
}

.free {
  position: absolute;
  width: 100px;
  height: 100px;
  background: url("../../../images/theme/free.svg") no-repeat top left transparent;

  z-index: 2;
  top: -8px;
  left: -8px;
}

.free2 {
  position: absolute;
  width: 93px;
  height: 88px;
  background: url(../../../images/theme/free.svg) no-repeat top left transparent;
  z-index: 10;
  filter: drop-shadow(2px 2px 4px rgba(000, 000, 000, 0.2));
  top: -4px;
  left: -4px;
}

.free3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../../../images/theme/free-white.svg") no-repeat top left transparent;

  z-index: 2;
  top: -8px;
  left: -8px;
}

.theater-mode {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player {
  position: relative;
}

.tm-btn {
  background-color: #2b2b2b;
  color: var(--bs-blue);
}

.tm-btn-form {
  background-color: #2b2b2b;
  border: none;
}

.tm-btn-form::placeholder {
  color: white;
  background-color: transparent;
}

.tm-btn-text {
  color: white;
}

.th-btns {
  border: 1px solid black;
}

.btn-tm {
  font-size: 14px;
}

/* form-background-gradient Theater Modal */

.form-background-gradient {
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%);
  opacity: 0.8;
}

.form-background-gradient-close {
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.5) 100%);
  opacity: 0.8;
}

/*FIX IFRAME*/
body #playerModal {
  display: block !important;
  transform: translateX(100%);
}

body #playerModal.show {
  display: block !important;
  transform: translateX(0);
}

/* Hover Animation */
#lessonPlayer {
  cursor: pointer;
}

#lessonPlayer .play2 {
  transform: scale(1);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

#lessonPlayer:hover .play2 {
  transform: scale(1.05);
}