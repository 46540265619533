/* Estilos para os títulos e itens da lista */
.filter-title {
    font-size: 17px;
  }

.filter-list {
  font-size: var(--filter-list-fs);
  text-decoration: none;
}

.view-more {
  color: #00a9ff;
  font-size: 9px;
  text-decoration: underline;
}

.hidden-list {
  display: none;
}

.hide {
  display: none;
}

.rotate {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.nav-link-custom {
  font-size: 18px;
  line-height: 1.4;
  text-decoration: none;
  /* margin-left: 15px; */
}

.nav-link-custom:hover {
  text-decoration: underline;
}

.rotate {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

svg {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.nav-link {
  text-decoration: none;
}

/* Fix for injected html */
aside li {
  margin-left: 15px;
  margin-top: 4px;
}

aside .filter-title>a {
  font-size: 17px;
  color: black;
  text-decoration: none;
}

aside .filter-title>a:hover {
  text-decoration: underline;
}