body.modal-open {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

/* Estilo adicional para o modal (opcional) */
.modal-content {
  background-color: #000;
  color: #fff;
}

.modal-body {
  padding: 0;
  height: calc(100vh - 96px);
  overflow: hidden !important;
}

@media (min-width:992px) {
  .modal-body {
    padding: 0;
    height: calc(100vh - 55px);
  }
}

.modal-title {
  color: #fff;
}