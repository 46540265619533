.fs-8 {
  font-size: var(--fs-8);
}

.fs-10 {
  font-size: var(--fs-10);
}

.fs-12 {
  font-size: var(--fs-12);
}

.fs-13 {
  font-size: var(--fs-13);
}

.fs-14 {
  font-size: var(--fs-14);
}

.fs-16 {
  font-size: var(--fs-16);
}

.fs-18 {
  font-size: var(--fs-18);
}

.fs-19 {
  font-size: var(--fs-19);
}

.fs-20 {
  font-size: var(--fs-20);
}

.fs-24 {
  font-size: var(--fs-24);
}

.fs-28 {
  font-size: var(--fs-28);
}

@media (min-width: 576px) {
  .fs-sm-8 {
    font-size: var(--fs-8);
  }

  .fs-sm-10 {
    font-size: var(--fs-10);
  }

  .fs-sm-12 {
    font-size: var(--fs-12);
  }

  .fs-sm-13 {
    font-size: var(--fs-13);
  }

  .fs-sm-14 {
    font-size: var(--fs-14);
  }

  .fs-sm-16 {
    font-size: var(--fs-16);
  }

  .fs-sm-18 {
    font-size: var(--fs-18);
  }

  .fs-sm-19 {
    font-size: var(--fs-19);
  }

  .fs-sm-20 {
    font-size: var(--fs-20);
  }

  .fs-sm-24 {
    font-size: var(--fs-24);
  }

  .fs-sm-28 {
    font-size: var(--fs-28);
  }
}

@media (min-width: 768px) {
  .fs-md-8 {
    font-size: var(--fs-8);
  }

  .fs-md-10 {
    font-size: var(--fs-10);
  }

  .fs-md-12 {
    font-size: var(--fs-12);
  }

  .fs-md-13 {
    font-size: var(--fs-13);
  }

  .fs-md-14 {
    font-size: var(--fs-14);
  }

  .fs-md-16 {
    font-size: var(--fs-16);
  }

  .fs-md-18 {
    font-size: var(--fs-18);
  }

  .fs-md-19 {
    font-size: var(--fs-19);
  }

  .fs-md-20 {
    font-size: var(--fs-20);
  }

  .fs-md-24 {
    font-size: var(--fs-24);
  }

  .fs-md-28 {
    font-size: var(--fs-28);
  }
}

@media (min-width: 992px) {
  .fs-lg-8 {
    font-size: var(--fs-8);
  }

  .fs-lg-10 {
    font-size: var(--fs-10);
  }

  .fs-lg-12 {
    font-size: var(--fs-12);
  }

  .fs-lg-13 {
    font-size: var(--fs-13);
  }

  .fs-lg-14 {
    font-size: var(--fs-14);
  }

  .fs-lg-16 {
    font-size: var(--fs-16);
  }

  .fs-lg-18 {
    font-size: var(--fs-18);
  }

  .fs-lg-19 {
    font-size: var(--fs-19);
  }

  .fs-lg-20 {
    font-size: var(--fs-20);
  }

  .fs-lg-24 {
    font-size: var(--fs-24);
  }

  .fs-lg-28 {
    font-size: var(--fs-28);
  }
}

@media (min-width: 1200px) {
  .fs-xl-8 {
    font-size: var(--fs-8);
  }

  .fs-xl-10 {
    font-size: var(--fs-10);
  }

  .fs-xl-12 {
    font-size: var(--fs-12);
  }

  .fs-xl-13 {
    font-size: var(--fs-13);
  }

  .fs-xl-14 {
    font-size: var(--fs-14);
  }

  .fs-xl-16 {
    font-size: var(--fs-16);
  }

  .fs-xl-18 {
    font-size: var(--fs-18);
  }

  .fs-xl-19 {
    font-size: var(--fs-19);
  }

  .fs-xl-20 {
    font-size: var(--fs-20);
  }

  .fs-xl-24 {
    font-size: var(--fs-24);
  }

  .fs-xl-28 {
    font-size: var(--fs-28);
  }
}

@media (min-width: 1400px) {
  .fs-xxl-8 {
    font-size: var(--fs-8);
  }

  .fs-xxl-10 {
    font-size: var(--fs-10);
  }

  .fs-xxl-12 {
    font-size: var(--fs-12);
  }

  .fs-xxl-13 {
    font-size: var(--fs-13);
  }

  .fs-xxl-14 {
    font-size: var(--fs-14);
  }

  .fs-xxl-16 {
    font-size: var(--fs-16);
  }

  .fs-xxl-18 {
    font-size: var(--fs-18);
  }

  .fs-xxl-19 {
    font-size: var(--fs-19);
  }

  .fs-xxl-20 {
    font-size: var(--fs-20);
  }

  .fs-xxl-24 {
    font-size: var(--fs-24);
  }

  .fs-xxl-28 {
    font-size: var(--fs-28);
  }
}

/*TODO: change all of the fonts to be responsive fonts */

.fs-tagline {
  font-size: var(--fs-12);
  font-weight: bold;
}

.filter-typo {
  --filter-title-fs: 19px;
  --filter-list-fs: var(--fs-14);
}

.underline-offset {
  text-underline-offset: 5px;
}

.text-primary {
  color: var(--bs-blue);
}

.badge-fs-sm-typo {
  font-size: var(--fs-10);
}

/* Guides Browse CSS */
.browse-guides-card-Title-typo {
  font-size: 17px;
}

.browse-guides-card-subTitle-typo {
  font-size: var(--fs-12);
}

.browse-guides-card-desc-typo {
  font-size: var(--fs-14);
  line-height: normal;
}

.browse-guides-dropdown-typo {
  font-size: 7px;
}

/* END of Guides Browse CSS */

/* GENERIC CONTENT CSS */
.generic-content p {
  font-size: var(--fs-12);
}

/* END of GENERIC CONTENT CSS */

/* Guides Browse CSS */
.guide-card-Title-typo {
  font-size: 17px;
}

.guide-card-subTitle-typo {
  font-size: var(--fs-13);
}

.guide-card-desc-typo {
  font-size: var(--fs-14);
}

.guide-dropdown-typo {
  font-size: 7px;
}

/* END of Guides Browse CSS */

/* Guide Lesson CSS */

.guide-lesson-card-comment-typo {
  font-size: var(--fs-10);
  color: #a3a3a3;
}



.guide-script {
  font-size: var(--fs-14);
}

/* END of Guides Lesson CSS */

/* Subscribe CSS */
.subscribe-only-typo {
  font-size: 11px;
}

/* END of Subscribe CSS */

/* Player-Typo CSS */
.tm-btn-form::placeholder {
  font-size: var(--fs-20);
}

.tm-btn-form imput {
  font-size: var(--fs-16);
}

.tm-btn-player-typo {
  font-size: var(--fs-16);
}

/* END of Player-Typo CSS */

/* Subscribe CSS */

.subscribe-btn-typo {
  font-size: var(--fs-14);
}

/* end of Subscribe CSS */

/* Form CSS */
.account-form-input-typo {
  font-size: 11px;
}

.account-form-input-typo {
  font-size: var(--fs-14);
}

/* end of Form CSS */

.swiper-title-typo {
  padding-top: 20px;
  font-size: 19px;
  text-decoration: none;
  color: var(--bs-body-color);
}

.contact-typo {
  font-size: var(--fs-12);
}

.text-pagination {
  color: black;
  font-size: var(--fs-13);
}

.text-pagination-active {
  color: black;
  font-weight: 800;
  font-size: var(--fs-13);
}



@media (max-width: 1200px) {
  .guide-lesson-card-comment-typo {
    font-size: 9px;
  }

  /* Player-Typo CSS */
  .tm-btn-form::placeholder {
    font-size: var(--fs-20);
  }

  .tm-btn-form imput {
    font-size: var(--fs-16);
  }

  .tm-btn-player-typo {
    font-size: var(--fs-12);
  }

  /* END of Player-Typo CSS */
}

@media (max-width: 992px) {
  .guide-lesson-card-comment-typo {
    font-size: var(--fs-12);
  }

  .guide-script {
    font-size: var(--fs-13);
  }

  .browse-guides-card-Title-typo {
    font-size: var(--fs-18);
  }

  /* GENERIC CONTENT CSS */
  .generic-content p {
    font-size: var(--fs-14);
  }

  /* Player-Typo CSS */
  .tm-btn-form::placeholder {
    font-size: var(--fs-20);
  }

  .tm-btn-form imput {
    font-size: var(--fs-18);
  }

  .tm-btn-player-typo {
    font-size: var(--fs-18);
  }

  /* END of Player-Typo CSS */
}

@media (max-width: 940px) {
  .guide-lesson-card-comment-typo {
    font-size: var(--fs-12);
  }

  .guide-script {
    font-size: var(--fs-13);
  }

  .browse-guides-card-Title-typo {
    font-size: var(--fs-18);
  }

  /* GENERIC CONTENT CSS */
  .generic-content p {
    font-size: var(--fs-14);
  }

  /* Player-Typo CSS */
  .tm-btn-form::placeholder {
    font-size: var(--fs-20);
  }

  .tm-btn-form imput {
    font-size: var(--fs-18);
  }

  .tm-btn-player-typo {
    font-size: var(--fs-18);
  }

  /* END of Player-Typo CSS */
}

@media (max-width: 768px) {
  .guide-lesson-card-comment-typo {
    font-size: 11px;
  }

  .guide-script {
    font-size: var(--fs-14);
  }

  .subscribe-only-typo {
    font-size: var(--fs-14);
  }

  .swiper-title-typo {
    padding-top: 20px;
    font-size: var(--fs-24);
  }

  .browse-guides-card-Title-typo {
    font-size: var(--fs-18);
  }

  /* Player-Typo CSS */
  .tm-btn-form::placeholder {
    font-size: var(--fs-20);
  }

  .tm-btn-form imput {
    font-size: var(--fs-18);
  }

  .tm-btn-player-typo {
    font-size: var(--fs-18);
  }

  /* END of Player-Typo CSS */
}

@media (max-width: 640px) {
  .guide-lesson-card-comment-typo {
    font-size: 11px;
  }

  .guide-script {
    font-size: var(--fs-16);
  }

  .browse-guides-card-Title-typo {
    font-size: var(--fs-18);
  }

  /* Player-Typo CSS */
  .tm-btn-form::placeholder {
    font-size: var(--fs-18);
  }

  .tm-btn-form imput {
    font-size: var(--fs-18);
  }

  .tm-btn-player-typo {
    font-size: var(--fs-16);
  }

  /* END of Player-Typo CSS */
}