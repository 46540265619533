.swiper {
  max-height: 380px;
}


.container-swiper {
  position: relative;
  overflow: visible !important;
  /* box-shadow: inset 0 -30px 30px -40px rgba(0, 0, 0, 0.8); */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
  color: #00a9ff;
  cursor: pointer;
  z-index: 10;
  position: absolute;
}


.swiper-button-prev {
  margin-top: -24px;
  margin-left: -48px;
}

.swiper-button-next {
  margin-top: -24px;
  margin-right: -48px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #007bb5;
}

@media (max-width: 980px) {
  .swiper {
    max-height: 500px;
  }
}