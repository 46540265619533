.custom-placeholder::placeholder {
  color: white !important;
  opacity: 0.3 !important;
}

.email-form {
  max-width: 150px;
}

@media (max-width: 991px) {
  .email-form {
    max-width: 230px;
  }
}

.link:hover {
  text-decoration: underline;
}
