/* html[data-page="home"]:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: url('../../../ui/home.png');
    z-index: 10000;
    background-position: center top;
    background-repeat: no-repeat;
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
} */